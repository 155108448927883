import { DOCUMENT } from '@angular/common';
import { Component, Inject, AfterContentInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentInit {
  public title = 'breello-front-office';

  constructor(@Inject(DOCUMENT) private document: Document) {}
  /**
   *
   */
  ngAfterContentInit(): void {
    if (environment.hotjarTrackingId) {
      ((h, o, t, j, a, r) => {
        h.hj =
          h.hj ||
          /* tslint:disable:only-arrow-functions */
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: environment.hotjarTrackingId, hjsv: 6 };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        a = o.getElementsByTagName('head')[0] as any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        r = o.createElement('script') as any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (r as any).async = 1;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (r as any).src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (a as any).appendChild(r);
      })(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window as any,
        this.document,
        'https://static.hotjar.com/c/hotjar-',
        '.js?sv=',
      );
    }
  }
}
