import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent {
  constructor(private modalService: NgbModal) {}

  /**
   * Navigates user to contact page.
   */
  public dismissModal(): void {
    this.modalService.dismissAll();
  }
}
