<app-navigation-bar
  [linearBackground]="true"
  (menuToggled)="updateScrolling()"
></app-navigation-bar>
<div class="landing-page" [ngClass]="{ menuOpen: !scrolling }">
  <div class="hero-image">
    <img src="assets/images/hero.jpg" alt="" />
    <app-panel-large (scrollToPrices)="scrollToPrices()"></app-panel-large>
    <div class="mask"></div>
  </div>
  <div class="landing-page-body top-section">
    <div class="value-proposition">
      <div
        class="proposition"
        *ngFor="let valueProposition of valuePropositions"
      >
        <div class="icon-black"></div>

        <div class="icon">
          <svg-icon-sprite
            [src]="'assets/sprites/sprite.svg#' + valueProposition.svg"
            [width]="'24px'"
            [height]="'24px'"
          ></svg-icon-sprite>
        </div>
        <div class="title">{{ valueProposition.title | translate }}</div>
        <div class="subtext">
          {{ valueProposition.description | translate }}
        </div>
        <div class="value-proposition-learn-more">
          <div
            class="value-proposition-learn-more-link"
            (click)="openValuePropositionModal(valueProposition)"
          >
            {{ "VALUE_PROPOSITION_LEARN_MORE" | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="carousels-title">
      <span>{{ "LATEST_LISTINGS" | translate }}</span>
    </div>
    <div class="carousel">
      <div class="custom-carousel-item" *ngFor="let house of properties">
        <app-carousel
          [title]="house.name"
          [number]="house.number"
          [price]="house.price"
          [link]="house.link"
          [image]="house.picture"
          [hasBoost]="house.hasBoost"
          [hasVIP]="house.hasVIP"
          [category]="house.category"
        ></app-carousel>
      </div>
    </div>
  </div>

  <div class="banner">
    <div class="banner-text-container">
      <div class="banner-text">
        {{ "BANNER_TEXT_1" | translate }}
      </div>
      <div class="banner-text">
        <span class="animated" [@textAnimation]="textState">{{ bannerAnimatedText | translate }}</span>
      </div>
      <div class="banner-text">
        {{ "BANNER_TEXT_2" | translate }}
      </div>
    </div>
    <div class="button-container">
      <a
        class="contact-button"
        (click)="trackContactClicks()"
        [routerLink]="'/contact'"
      >
        <div class="text">
          {{ "LANDING_PAGE_CONTACT_BUTTON" | translate }}
        </div>
      </a>
    </div>
  </div>

  <div class="landing-page-body bottom-section">
    <div class="steps-container">
      <div class="steps-title-container">
        <div class="title">
          {{ "LANDING_PAGE_MAKE_EVERYTHING_SIMPLE" | translate }}
        </div>
        <div class="image">
          <img src="assets/icons/breello-make-everything-simple.svg" alt="" />
        </div>
      </div>
      <div class="steps">
        <div *ngFor="let step of steps" class="step">
          <div class="step-left">
            <div class="icon-container">
              <div class="icon-black"></div>
              <div class="icon">{{ step.number }}</div>
            </div>
          </div>
          <div class="step-right">
            <div class="list-item-text">
              <div class="title list">
                <div class="list-text">
                  {{ step.title | translate }}
                </div>
              </div>
              <div class="main-text list">
                {{ step.description | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="prices-title">
      {{ "PRICE_TITLE" | translate }}
    </div>

    <div #pricesPanel class="prices-panels">
      <div class="panel" *ngFor="let pricePanel of pricePanels">
        <app-price [price]="pricePanel"> </app-price>
      </div>
    </div>

    <div class="extra-services-title">
      {{ "EXTRA_SERVICES_TITLE" | translate }}
    </div>

    <div class="extra-services-container">
      <div class="extra-service" *ngFor="let service of extraServices">
        <app-extra-service-card [card]="service"></app-extra-service-card>
      </div>
    </div>

    <a class="contact-button" [routerLink]="'/contact'">
      <div class="text">
        {{ "LANDING_PAGE_CONTACT_BUTTON" | translate }}
      </div>
    </a>

    <div id="faq" class="faq-container">
      <app-faq></app-faq>
      <div class="image">
        <img src="assets/icons/breello-steps.svg" alt="" />
      </div>
    </div>

    <div class="contact-container">
      <div class="image">
        <img src="assets/icons/breello-contact.svg" alt="" />
      </div>
      <div class="contact-content">
        <div class="title">
          <div class="text">{{ "LANDING_PAGE_CONTACT_TITLE" | translate }}</div>
        </div>
        <div
          class="main-text"
          [innerHtml]="'LANDING_PAGE_CONTACT_TEXT' | translate"
        ></div>
        <a class="contact-button" [routerLink]="'/contact'">
          <div class="text">
            {{ "LANDING_PAGE_CONTACT_BUTTON" | translate }}
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
