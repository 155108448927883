<div *ngIf="card" class="other-service-card">
  <div class="image">
    <div class="icon-black"></div>
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#' + card.svg"
        [width]="'24px'"
        [height]="'24px'"
      ></svg-icon-sprite>
    </div>
  </div>
  <div class="text">{{ card.text | translate }}</div>
  <div
    class="question-mark"
    placement="top-end"
    [ngbTooltip]="infoTooltipTemplate"
    [tooltipClass]="'info-tooltip'"
    #infoTooltip
    [popperOptions]="popperOptions"
    [closeDelay]="1000"
  >
    <svg-icon-sprite
      [src]="'assets/sprites/sprite.svg#question_outline'"
      [width]="'24px'"
      [height]="'24px'"
    ></svg-icon-sprite>
  </div>
  <ng-template #infoTooltipTemplate>
    <div class="tooltip-container">
      <div class="tooltiptext">{{ card.infoText | translate }}</div>
      <div class="contact-link">
        <a [routerLink]="'/contact'" (click)="trackContactClicks()">
          {{ "OTHER_SERVICE_CARD_CONTACT_LINK" | translate }}
        </a>
      </div>
    </div>
  </ng-template>
</div>
