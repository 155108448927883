<app-navigation-bar
  [isLight]="true"
  (menuToggled)="updateScrolling()"
></app-navigation-bar>
<div class="main-content">
  <div class="two-col-content">
    <div class="second-col">
      <div class="image-top">
        <img src="assets/icons/breello-contact.svg" alt="" />
      </div>
      <div class="title">
        <div class="text">{{ "CONTACT_PAGE_TITLE" | translate }}</div>
      </div>
      <div class="main-text">
        {{ "CONTACT_PAGE_DESCRIPTION" | translate }}
        <a href="mailto:hello@breello.gr" class="mail-link"
          >hello&#64;breello.gr
        </a>
        {{ "CONTACT_PAGE_DESCRIPTION_2" | translate }}
      </div>
      <div class="form">
        <form [formGroup]="contactForm">
          <form
            ngNoForm
            action="https://submit-form.com/MbgkuV02"
            method="POST"
          >
            <input
              type="hidden"
              name="_redirect"
              value="https://breello.gr/contact?success=true"
            />
            <input type="hidden" name="_email.subject" value="Contact" />
            <div class="form-section">
              <label>
                <div class="text">
                  {{ "CONTACT_NAME" | translate }}
                </div>
                <div class="input">
                  <input
                    id="name"
                    type="text"
                    name="name"
                    formControlName="name"
                    placeholder="{{ 'CONTACT_NAME_PLACEHOLDER' | translate }}"
                  />
                  <div
                    class="error-icon"
                    *ngIf="
                      contactForm.get('name')?.invalid &&
                      (contactForm.get('name')?.dirty ||
                        contactForm.get('name')?.touched)
                    "
                  >
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#alert'"
                      [width]="'16px'"
                      [height]="'16px'"
                    ></svg-icon-sprite>
                  </div>
                </div>
                <div
                  class="error"
                  *ngIf="
                    contactForm.get('name')?.invalid &&
                    (contactForm.get('name')?.dirty ||
                      contactForm.get('name')?.touched)
                  "
                >
                  <ng-container
                    *ngIf="contactForm.get('name')?.errors?.required"
                  >
                    {{ "NAME_ERROR_REQUIRED" | translate }}
                  </ng-container>
                </div>
              </label>
            </div>
            <div class="form-section">
              <label>
                <div class="text">
                  {{ "CONTACT_PHONE" | translate }}
                </div>
                <div class="input">
                  <input
                    id="phone"
                    type="number"
                    name="phone"
                    formControlName="phone"
                    placeholder="{{ 'CONTACT_PHONE_PLACEHOLDER' | translate }}"
                    [ngClass]="{
                      'error-input':
                        blurStates.phone &&
                        contactForm.get('phone')?.invalid &&
                        (contactForm.get('phone')?.dirty ||
                          contactForm.get('phone')?.touched),
                    }"
                    (blur)="blurStates.phone = true"
                  />
                  <div
                    class="error-icon custom-tooltip"
                    *ngIf="
                      blurStates.phone &&
                      contactForm.get('phone')?.invalid &&
                      (contactForm.get('phone')?.dirty ||
                        contactForm.get('phone')?.touched)
                    "
                  >
                    <span class="tooltiptext">
                      {{ "PHONE_ERROR_TOOLTIP" | translate }}</span
                    >
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#alert'"
                      [width]="'16px'"
                      [height]="'16px'"
                    ></svg-icon-sprite>
                  </div>
                </div>
                <div
                  class="error"
                  *ngIf="
                    blurStates.phone &&
                    contactForm.get('phone')?.invalid &&
                    (contactForm.get('phone')?.dirty ||
                      contactForm.get('phone')?.touched)
                  "
                >
                  <ng-container
                    *ngIf="contactForm.get('phone')?.errors?.required"
                  >
                    {{ "PHONE_ERROR_REQUIRED" | translate }}
                  </ng-container>
                  <ng-container
                    *ngIf="contactForm.get('phone')?.errors?.pattern"
                  >
                    {{ "PHONE_ERROR_INVALID" | translate }}
                  </ng-container>
                </div>
              </label>
            </div>
            <div class="form-section">
              <label>
                <div class="form-section">
                  <div class="text">
                    {{ "CONTACT_EMAIL" | translate }}
                  </div>
                  <div class="input">
                    <input
                      id="email"
                      type="text"
                      name="email"
                      formControlName="email"
                      placeholder="{{
                        'CONTACT_EMAIL_PLACEHOLDER' | translate
                      }}"
                      [ngClass]="{
                        'error-input':
                          blurStates.email &&
                          contactForm.get('email')?.invalid &&
                          (contactForm.get('email')?.dirty ||
                            contactForm.get('email')?.touched),
                      }"
                      (blur)="blurStates.email = true"
                    />
                    <div
                      class="error-icon"
                      *ngIf="
                        blurStates.email &&
                        contactForm.get('email')?.invalid &&
                        (contactForm.get('email')?.dirty ||
                          contactForm.get('email')?.touched)
                      "
                    >
                      <svg-icon-sprite
                        [src]="'assets/sprites/sprite.svg#alert'"
                        [width]="'16px'"
                        [height]="'16px'"
                      ></svg-icon-sprite>
                    </div>
                  </div>
                  <div
                    class="error"
                    *ngIf="
                      blurStates.email &&
                      contactForm.get('email')?.invalid &&
                      (contactForm.get('email')?.dirty ||
                        contactForm.get('email')?.touched)
                    "
                  >
                    <ng-container
                      *ngIf="contactForm.get('email')?.errors?.required"
                    >
                      {{ "EMAIL_ERROR_REQUIRED" | translate }}
                    </ng-container>
                    <ng-container
                      *ngIf="contactForm.get('email')?.errors?.email"
                    >
                      {{ "EMAIL_ERROR_INVALID" | translate }}
                    </ng-container>
                  </div>
                </div>
              </label>
            </div>
            <div class="form-section">
              <label>
                <div class="form-section">
                  <div class="text">
                    <div class="title">
                      {{ "CONTACT_MESSAGE" | translate }}
                    </div>
                    <div class="description">
                      {{ "CONTACT_MESSAGE_DESCRIPTION" | translate }}
                    </div>
                  </div>
                  <div class="input">
                    <textarea
                      id="message"
                      name="massage"
                      formControlName="message"
                      placeholder="{{
                        'CONTACT_MESSAGE_PLACEHOLDER' | translate
                      }}"
                      formControlName="message"
                      cols="48"
                      rows="8"
                    ></textarea>
                  </div>
                </div>
              </label>
            </div>
            <div class="form-section-checkbox">
              <div class="input">
                <input
                  type="checkbox"
                  formControlName="terms"
                  id="acceptTerms"
                  class="form-check-input"
                />
              </div>
              <div class="text">
                <label
                  for="acceptTerms"
                  class="form-check-label"
                  (click)="navigateToTerms()"
                >
                  {{ "CONTACT_TERMS" | translate }}
                </label>
              </div>
            </div>
            <div class="recaptcha">
              <re-captcha formControlName="recaptchaReactive"></re-captcha>
            </div>
            <button
              type="submit"
              (click)="googleCta()"
              [ngClass]="{
                disabled: !contactForm.valid,
              }"
              [disabled]="!contactForm.valid"
            >
              <div class="text">{{ "CONTACT_MESSAGE_BUTTON" | translate }}</div>
            </button>
          </form>
        </form>
      </div>
    </div>
    <div class="first-col">
      <div class="image">
        <img src="assets/icons/breello-contact.svg" alt="" />
      </div>
      <div class="contact-details">
        <div class="contact-detail-title">
          {{ "CONTACT_DETAILS" | translate }}
        </div>
        <div class="contact-detail">
          <div class="contact-detail-label">
            {{ "CONTACT_ADDRESS" | translate }}
          </div>
          <div>
            {{ "CONTACT_ADDRESS_INFO" | translate }}
          </div>
        </div>
        <div class="contact-detail">
          <div class="contact-detail-label">
            {{ "CONTACT_PHONE_NUMBER" | translate }}
          </div>
          <div>
            {{ "CONTACT_PHONE_NUMBER_INFO" | translate }}
          </div>
        </div>
        <div class="contact-detail">
          <div class="contact-detail-label">
            {{ "CONTACT_PERSON" | translate }}
          </div>
          <div>
            {{ "CONTACT_PERSON_INFO" | translate }}
          </div>
        </div>
      </div>
      <!-- <div class="contact-info">
        <div class="headquarters">{{ "HEADQUARTERS" | translate }}</div>
        <div class="info-box">
          <div class="top">
            <div class="place">Egnatia 154, Thessaloniki, 546 36, Greece</div>
            <div class="icon">
              <svg-icon-sprite
                [src]="'assets/sprites/sprite.svg#map-pin-color'"
                [width]="'40px'"
                [height]="'40px'"
              ></svg-icon-sprite>
              <div class="dot"></div>
            </div>
          </div>
          <div class="email"> hello@breello.gr</div>
        </div>
      </div> -->
    </div>
  </div>
</div>
<app-footer></app-footer>
