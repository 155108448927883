<div class="panel-large">
  <div class="top">
    <div class="title">
      {{ "ΗERO_TITLE" | translate }}
    </div>
    <div class="panel-text">
      {{ "ΗERO_TEXT" | translate }}
    </div>
  </div>
  <div class="button-container">
    <div
      class="button publish"
      [ngClass]="{ disabled: disabled }"
      (click)="googleCtaPublish()"
    >
      <a href="{{ backOfficeURL }}/sign-up-options">
        <div class="text">
          {{ "ΗERO_BUTTON_PUBLISH" | translate }}
        </div>
      </a>
    </div>
    <div
      class="button find-out-cost"
      [ngClass]="{ disabled: disabled }"
      (click)="googleCtaFindOutCost()"
    >
      <a>
        <div class="text">
          {{ "ΗERO_BUTTON_FIND_OUT_COST" | translate }}
        </div>
      </a>
    </div>
  </div>
</div>
