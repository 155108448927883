import { Component, Input } from '@angular/core';
import { IQuestion } from '../faq/faq.component';

@Component({
  selector: 'app-question-dropdown',
  templateUrl: './question-dropdown.component.html',
  styleUrls: ['./question-dropdown.component.scss'],
})
export class QuestionDropdownComponent {
  // Inputs/Outputs
  @Input() public question?: IQuestion;

  // Public/Private properties.
  public expanded: boolean;
  constructor() {
    this.expanded = false;
  }
}
