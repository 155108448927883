import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-panel-large',
  templateUrl: './panel-large.component.html',
  styleUrls: ['./panel-large.component.scss'],
})
export class PanelLargeComponent implements OnInit {
  @Output() public scrollToPrices = new EventEmitter<void>();
  // Public properties.
  public disabled: boolean = false;
  public backOfficeURL: string;

  constructor(
    private router: Router,
    private $gaService: GoogleAnalyticsService,
  ) {
    this.backOfficeURL = environment.backOfficeURL;
  }

  /**
   *
   */
  ngOnInit(): void {
    this.disabled = false;
  }

  /**
   * Sends a google event.
   */
  public googleCtaPublish(): void {
    this.$gaService.event('Index-Hero-Publish-CTA', 'Click', 'Publish-CTA');
  }

  /**
   * Sends a google event.
   */
  public googleCtaFindOutCost(): void {
    this.$gaService.event(
      'Index-Hero-Find-Out-Cost-CTA',
      'Click',
      'Find-Out-Cost-CTA',
    );
    this.scrollToPrices.emit();
  }
}
