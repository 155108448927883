import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(private router: Router) {}

  /**
   * Calculates the current year.
   * @returns {string} The current year in string format.
   */
  public getCurrentYear(): string {
    return new Date().getFullYear().toString();
  }
}
