import { Component, Input } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { PlatformStatus } from 'src/app/enumerations/platform-status.enumeration';
import { environment } from 'src/environments/environment';
import { IPricePanel } from '../landing/landing.component';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent {
  // Inputs/Outputs.
  @Input() public price?: IPricePanel;
  // Public properties.
  public PlatformStatus = PlatformStatus;
  public backOfficeURL: string;
  constructor(private $gaService: GoogleAnalyticsService) {
    this.PlatformStatus = PlatformStatus;
    this.backOfficeURL = environment.backOfficeURL;
  }

  /**
   * Renders tooltip platforms.
   * @returns {string} The result
   */
  public renderPlatforms(): string {
    if (this.price?.platforms != null && this.price.platforms.length > 0) {
      return this.price.platforms
        .map((platform) => platform.link)
        .join('<br /> ');
    } else {
      return '';
    }
  }

  /**
   * Emits the 'package' CTA.
   */
  public packageCta(): void {
    if (this.price?.type === 'PRICE_BASIC') {
      this.$gaService.event('Price-BasicPackage-CTA', 'Click', 'Register-CTA');
    } else if (this.price?.type === 'PRICE_MAIN') {
      this.$gaService.event('Price-MainPackage-CTA', 'Click', 'Register-CTA');
    } else {
      this.$gaService.event('Price-BoostPackage-CTA', 'Click', 'Register-CTA');
    }
  }
}
