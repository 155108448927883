<div
  class="navigation-bar"
  [ngClass]="{
    scrolled: scrollingPosition !== 0,
    light: isLight,
    'linear-background': linearBackground,
  }"
>
  <div class="logo" (click)="navigateToHome()">
    <img src="assets/icons/logo-light.svg" alt="" class="logo-light" />
    <img src="assets/icons/logo-dark.svg" alt="" class="logo-dark" />
  </div>
  <div class="navigation-buttons">
    <a class="button first" [routerLink]="'/contact'">
      <div class="text">
        {{ "NAVIGATION_BAR_CONTACT" | translate }}
      </div>
    </a>
    <div class="button-full" (click)="googleCta()">
      <a href="{{ backOfficeURL }}/sign-up-options">
        <div class="text">
          {{ "NAVIGATION_BAR_LIST" | translate }}
        </div>
      </a>
    </div>
    <div class="button-border">
      <a href="{{ backOfficeURL }}">
        <div class="text">
          {{ "NAVIGATION_BAR_SIGN_IN" | translate }}
        </div>
      </a>
    </div>
  </div>
</div>
<div
  class="navigation-bar-mobile"
  [ngClass]="{
    light: isLight,
    slidingMenu: showSlidingMenu,
    scrolled: scrollingPosition !== 0,
  }"
>
  <div class="visible-menu">
    <div class="logo" *ngIf="!showSlidingMenu" (click)="navigateToHome()">
      <img src="assets/icons/logo-light.svg" alt="" class="logo-light" />
      <img src="assets/icons/logo-dark.svg" alt="" class="logo-dark" />
    </div>
    <div class="icon" (click)="openMenu()" *ngIf="!showSlidingMenu">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#navigational-menu'"
        [width]="'24px'"
        [height]="'24px'"
      ></svg-icon-sprite>
    </div>
  </div>
  <div
    class="sliding-menu"
    [ngClass]="{ open: showSlidingMenu, close: !showSlidingMenu }"
  >
    <app-sliding-menu (menuClosed)="closeMenu()"></app-sliding-menu>
  </div>
  <div
    class="sticky"
    [ngClass]="{
      visible: scrollingPosition !== 0,
    }"
  >
    <div class="button-full" (click)="googleCta()">
      <a href="{{ backOfficeURL }}/sign-up-options">
        <div class="text">
          {{ "NAVIGATION_BAR_LIST" | translate }}
        </div>
      </a>
    </div>
  </div>
</div>
