<div class="question-dropdown">
  <div
    class="question"
    (click)="this.expanded = !this.expanded"
    [ngClass]="{ expanded: this.expanded }"
  >
    <div class="text">{{ question?.question + "" | translate }}</div>
    <div class="icon" *ngIf="!this.expanded">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#chevron_down'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
    <div class="icon" *ngIf="this.expanded">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#chevron_up'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
  </div>
  <div
    class="answer"
    *ngIf="this.expanded"
    [innerHTML]="question?.answer + '' | translate"
  ></div>
</div>
