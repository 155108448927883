import { Component } from '@angular/core';

export interface IQuestion {
  question: string;
  answer: string;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  // Public properties.
  public questions: IQuestion[] = [];

  constructor() {
    this.questions = [
      {
        question: 'FAQ_QUESTION_1',
        answer: 'FAQ_ANSWER_1',
      },
      {
        question: 'FAQ_QUESTION_2',
        answer: 'FAQ_ANSWER_2',
      },
      {
        question: 'FAQ_QUESTION_3',
        answer: 'FAQ_ANSWER_3',
      },
      {
        question: 'FAQ_QUESTION_4',
        answer: 'FAQ_ANSWER_4',
      },
    ];
  }
}
