import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { map, take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  // Public/private properties.
  public scrolling = true;
  public contactForm = this.fb.group({
    email: ['', Validators.compose([Validators.email, Validators.required])],
    name: ['', Validators.required],
    phone: [
      '',
      Validators.compose([Validators.required, Validators.pattern(/^\d{10}$/)]),
    ],
    message: [''],
    terms: [false, Validators.requiredTrue],
    recaptchaReactive: [null, Validators.required],
  });
  public blurStates = {
    email: false,
    phone: false,
  };

  constructor(
    private fb: UntypedFormBuilder,
    private $gaService: GoogleAnalyticsService,
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    this.translateService
      .get('CONTACT_TITLE')
      .pipe(take(1))
      .subscribe((translation: string) => {
        this.titleService.setTitle(translation);
      });

    this.route.queryParamMap
      .pipe(map((params: ParamMap) => params.get('success')))
      .subscribe((data) => {
        if (data === 'true') {
          this.modalService.open(ContactModalComponent, {
            centered: false,
            size: 'xl',
            backdrop: true,
            modalDialogClass: 'contact-modal-dialog',
          });
        }
      });
  }

  /**
   * Prevents scrolling when sliding menu is open.
   */
  public updateScrolling(): void {
    this.scrolling = !this.scrolling;
    if (!this.scrolling) {
      $('body').css('overflow', 'hidden');
    } else {
      $('body').css('overflow', 'auto');
    }
  }

  /**
   * Sends a google event.
   */
  public googleCta(): void {
    this.$gaService.event('Contact-Form-Send-CTA', 'Click', 'Contact-Send-CTA');
  }

  /**
   * Navigates user to terms page.
   */
  public navigateToTerms(): void {
    this.router.navigate(['/terms']);
    window.scroll(0, 0); // reset the scroll position to the top left of the document.
  }
}
