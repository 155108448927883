<div class="carousel-item" (click)="openItem()">
  <div class="image">
    <img [src]="'assets/images/' + image" alt="" />
  </div>
  <div class="title">{{ title | translate }}</div>
  <div class="last-row">
    <div class="price">{{ price }}</div>
    <div class="category" [ngClass]="{ rent: category === 'RENT' }">
      <div class="category-text">
        {{ category | translate }}
      </div>
    </div>
    <!-- <div class="icons">
      <img [src]="'assets/images/realtor.jpg'" alt="" />
      <img [src]="'assets/images/rentcom.jpg'" alt="" />
      <img [src]="'assets/images/zillow.jpg'" alt="" />
      <div class="number">+ {{ number }} {{ "CAROUSEL_MORE" | translate }}</div>
    </div> -->
  </div>
  <div class="boosted" *ngIf="hasBoost">
    <div class="text">{{ "BOOSTED" | translate }}</div>
    <svg-icon-sprite
      [src]="'assets/sprites/sprite.svg#up-up'"
      [width]="'16px'"
      [height]="'16px'"
    ></svg-icon-sprite>
  </div>
  <div class="vip" *ngIf="hasVIP">
    <div class="text">{{ "VIP" | translate }}</div>
    <svg-icon-sprite
      [src]="'assets/sprites/sprite.svg#star'"
      [width]="'16px'"
      [height]="'16px'"
    ></svg-icon-sprite>
  </div>
</div>
