import { Component, Input, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IValueProposition } from '../landing/landing.component';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-value-proposition-modal',
  templateUrl: './value-proposition-modal.component.html',
  styleUrl: './value-proposition-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ValuePropositionModalComponent implements OnInit {
  @Input() public valueProposition?: IValueProposition;
  public safeHtml: string | null = null;
  constructor(
    private modalService: NgbModal,
    private domSanitizer: DomSanitizer,
    public translateService: TranslateService,
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    if (this.valueProposition?.fulltext) {
      this.safeHtml = this.domSanitizer.sanitize(
        SecurityContext.HTML,
        this.translateService.instant(this.valueProposition.fulltext),
      );
    }
  }

  /**
   * Navigates user to contact page.
   */
  public dismissModal(): void {
    this.modalService.dismissAll();
  }
}
