import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  // Public/private properties.
  public scrolling = true;

  constructor(
    private router: Router,
    private titleService: Title,
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    this.titleService.setTitle('Breello');
  }

  /**
   * Prevents scrolling when sliding menu is open.
   */
  public updateScrolling(): void {
    this.scrolling = !this.scrolling;
    if (!this.scrolling) {
      $('body').css('overflow', 'hidden');
    } else {
      $('body').css('overflow', 'auto');
    }
  }

  /**
   * Navigates user to contact page.
   */
  public navigateToContact(): void {
    this.router.navigate(['/contact']);
    window.scroll(0, 0); // reset the scroll position to the top left of the document.
  }
}
