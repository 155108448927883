<div class="sliding-menu">
  <div class="top">
    <div class="close-button" (click)="closeMenu()">
      <div class="icon">
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#close'"
          [width]="'32px'"
          [height]="'32px'"
        ></svg-icon-sprite>
      </div>
    </div>
    <div class="links">
      <div class="link first" (click)="navigateToHome()">
        <div class="text">{{ "NAVIGATION_BAR_HOME" | translate }}</div>
      </div>
      <div class="link" (click)="trackPublishClicks()">
        <a href="{{ backOfficeURL }}/sign-up-options">
          {{ "NAVIGATION_BAR_LIST" | translate }}</a
        >
      </div>
      <div class="link" (click)="navigateToContact()">
        <div class="text">{{ "NAVIGATION_BAR_CONTACT" | translate }}</div>
      </div>
      <div class="link last">
        <a href="/about#faq">{{ "NAVIGATION_BAR_FAQ" | translate }}</a>
      </div>
    </div>
    <!-- <div class="button-outline">
      <div class="text">
        <div class="lang">English</div>
        <div class="icon">
          <svg-icon-sprite
            [src]="'assets/sprites/sprite.svg#down'"
            [width]="'10px'"
            [height]="'20px'"
          ></svg-icon-sprite>
        </div>
      </div>
    </div> -->
    <div class="bottom">
      <div class="button-fill">
        <a href="{{ backOfficeURL }}">
          <div class="text">{{ "NAVIGATION_BAR_SIGN_IN" | translate }}</div>
        </a>
      </div>
    </div>
  </div>
  <!-- <div class="social-media">
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#twitter-copy'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#facebook-copy'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#linkedin-copy'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#instagram-copy'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#youtube-copy'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
  </div> -->
</div>
