import {
  Component,
  OnInit,
  Input,
  HostListener,
  EventEmitter,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit {
  // Inputs/Outputs
  @Input() public isLight = false;
  @Input() public linearBackground = false;
  @Output() public menuToggled = new EventEmitter<void>();

  // Public properites.
  public scrollingPosition: number;
  public showSlidingMenu: boolean = false;
  public backOfficeURL: string;

  constructor(
    private router: Router,
    private $gaService: GoogleAnalyticsService,
  ) {
    this.scrollingPosition = 0;
    this.backOfficeURL = environment.backOfficeURL;
  }

  // DOM manipulation.
  /**
   *
   */
  @HostListener('window:scroll', ['$event'])
  public updateScrollPosition(): void {
    this.scrollingPosition = window.scrollY;
  }

  /**
   *
   */
  ngOnInit(): void {
    this.showSlidingMenu = false;
  }

  /**
   * closeMenu
   */
  public closeMenu(): void {
    this.showSlidingMenu = !this.showSlidingMenu;
    this.menuToggled.emit();
  }

  /**
   * opens sliding menu.
   *
   */
  public openMenu(): void {
    this.menuToggled.emit();
    this.showSlidingMenu = !this.showSlidingMenu;
  }

  /**
   * Navigates user to home page.
   */
  public navigateToHome(): void {
    this.router.navigate(['/']);
    window.scroll(0, 0); // reset the scroll position to the top left of the document.
  }

  /**
   * Sends a google event.
   */
  public googleCta(): void {
    this.$gaService.event('Top-Menu-Publish-CTA', 'Click', 'Publish-CTA');
  }
}
