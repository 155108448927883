<div class="close-button" (click)="dismissModal()">
  <div class="text">{{ "CONTACT_MODAL_CLOSE" | translate }}</div>
  <div class="icon">
    <svg-icon-sprite
      [src]="'assets/sprites/sprite.svg#close'"
      [width]="'24px'"
      [height]="'24px'"
    ></svg-icon-sprite>
  </div>
</div>
<div class="contact-modal">
  <div class="icon">
    <img src="../../../../assets/icons/group_2.svg" alt="" />
  </div>
  <div class="title">{{ "CONTACT_MODAL_TITLE" | translate }}</div>
  <div class="main-text">
    {{ "CONTACT_MODAL_TEXT" | translate }}
  </div>
</div>
