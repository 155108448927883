<div class="faq-page">
  <div class="page-title">
    {{ "NAVIGATION_BAR_FAQ" | translate }}
  </div>
  <div class="question-items">
    <div class="question-item" *ngFor="let question of questions">
      <app-question-dropdown [question]="question"> </app-question-dropdown>
    </div>
  </div>
</div>
