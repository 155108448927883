import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FaqComponent } from './components/faq/faq.component';
import { ReactiveFormsModule } from '@angular/forms';

import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { LandingComponent } from './components/landing/landing.component';
import { SlidingMenuComponent } from './components/sliding-menu/sliding-menu.component';
import { PanelSmallComponent } from './components/panel-small/panel-small.component';
import { PanelLargeComponent } from './components/panel-large/panel-large.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { QuestionDropdownComponent } from './components/question-dropdown/question-dropdown.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { RegisterComponent } from './components/register/register.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactModalComponent } from './components/contact-modal/contact-modal.component';
import { PriceComponent } from './components/price/price.component';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RecaptchaModule,
  RecaptchaFormsModule,
} from 'ng-recaptcha';
import { ExtraServiceCardComponent } from './components/extra-service-card/extra-service-card.component';
import { ValuePropositionModalComponent } from './components/value-proposition-modal/value-proposition-modal.component';

// AoT requires an exported function for factories.
/**
 * HttpLoaderFactory
 * @param {HttpClient} http The HttpClient
 * @returns {TranslateHttpLoader} The response
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FaqComponent,
    ContactComponent,
    AboutComponent,
    FooterComponent,
    NavigationBarComponent,
    LandingComponent,
    SlidingMenuComponent,
    PanelSmallComponent,
    PanelLargeComponent,
    QuestionDropdownComponent,
    CarouselComponent,
    RegisterComponent,
    TermsAndConditionsComponent,
    ContactModalComponent,
    PriceComponent,
    ExtraServiceCardComponent,
    ValuePropositionModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconSpriteModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'el',
    }),
    ReactiveFormsModule,
    NgxGoogleAnalyticsModule.forRoot('G-71J46Z8N4R'),
    NgxGoogleAnalyticsRouterModule,
    ScullyLibModule.forRoot(),
    NgbModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule
  ],
  exports: [
    FooterComponent,
    NavigationBarComponent,
    SlidingMenuComponent,
    PanelSmallComponent,
    PanelLargeComponent,
    QuestionDropdownComponent,
    FaqComponent,
    CarouselComponent,
    ExtraServiceCardComponent,
    ValuePropositionModalComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LedhvEaAAAAABP9aFtzcconQxoObgIvxMAHToaH',
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
