<div *ngIf="price" class="price" [ngClass]="{ limited: price.limited }">
  <div class="title-container">
    <div class="title">
      {{ price.type + "" | translate }}
    </div>
    <div class="limited-badge" *ngIf="price.limited">
      <div class="icon">
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#star'"
          [width]="'14px'"
          [height]="'14px'"
        ></svg-icon-sprite>
      </div>
      <div class="text">
        {{ "PRICE_LIMITED_BADGE" | translate }}
      </div>
    </div>
  </div>

  <div class="price-value" [ngClass]="{ limited: price.limited }">
    <ng-container *ngIf="!price.boosted">
      {{ price.value + "" | translate }}
    </ng-container>
    <div class="boost-price" *ngIf="price.boosted">
      <div class="value">
        {{ price.valuePrice + "" | translate }}
      </div>
      <div class="price-weekly">/ {{ "PRICE_WEEKLY" | translate }}</div>
    </div>
    <div class="initial-price" *ngIf="price.limited">
      <div class="value">
        {{ price.valuePrice + "" | translate }}
      </div>
      <div class="price-montly">/ {{ "PRICE_MONTHLY" | translate }}</div>
    </div>
  </div>
  <div class="limited-days">
    {{ price.limited ? ("PRICE_DAYS_REMAINING" | translate) : "" }}
  </div>
  <div class="description">
    {{ price.description + "" | translate }}
  </div>
  <div *ngIf="price.platforms" class="platforms">
    <ng-container *ngFor="let platform of price.platforms; let i = index">
      <div class="platform" *ngIf="i < 4">
        <div
          class="icon"
          [ngClass]="{
            'vip-gold':
              platform.status === PlatformStatus.VIP ||
              platform.status === PlatformStatus.GOLD,
          }"
        >
          <svg-icon-sprite
            *ngIf="platform.status === PlatformStatus.SIMPLE"
            [src]="'assets/sprites/sprite.svg#check'"
            [width]="'16px'"
            [height]="'16px'"
          ></svg-icon-sprite>
          <svg-icon-sprite
            *ngIf="platform.status !== PlatformStatus.SIMPLE"
            [src]="'assets/sprites/sprite.svg#star'"
            [width]="'16px'"
            [height]="'16px'"
          ></svg-icon-sprite>
        </div>
        <div
          class="link"
          [ngClass]="{
            'vip-gold':
              platform.status === PlatformStatus.VIP ||
              platform.status === PlatformStatus.GOLD,
          }"
        >
          {{ platform.link }}
        </div>
        <div
          class="promotion"
          *ngIf="platform.status !== PlatformStatus.SIMPLE"
        >
          &nbsp;-
          <ng-container *ngIf="platform.status === PlatformStatus.VIP">
            {{ "PRICE_PROMOTION_VIP" | translate }}
          </ng-container>
          <ng-container *ngIf="platform.status === PlatformStatus.GOLD">
            {{ "PRICE_PROMOTION_GOLD" | translate }}
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div
      class="more custom-tooltip"
      *ngIf="price.platforms != null && price.platforms.length > 4"
    >
      +{{ price.platforms.length - 4 }}
      {{ "PRICE_PLATFORMS_MORE" | translate }}
      <span class="tooltiptext" [innerHtml]="renderPlatforms()"></span>
    </div>
  </div>

  <div *ngIf="price.features" class="features">
    <ng-container *ngFor="let feature of price.features">
      <div class="feature">
        <div
          class="icon"
          [ngClass]="{
            'vip-gold': feature.vip,
          }"
        >
          <svg-icon-sprite
            *ngIf="!feature.vip"
            [src]="'assets/sprites/sprite.svg#check'"
            [width]="'16px'"
            [height]="'16px'"
          ></svg-icon-sprite>
          <svg-icon-sprite
            *ngIf="feature.vip"
            [src]="'assets/sprites/sprite.svg#star'"
            [width]="'16px'"
            [height]="'16px'"
          ></svg-icon-sprite>
        </div>
        <div class="feature-text">
          {{ feature.text | translate }}
        </div>
      </div>
    </ng-container>
  </div>
  <div class="options">
    <div class="button-full" (click)="packageCta()">
      <a href="{{ backOfficeURL }}/sign-up-options">
        <div class="text">
          {{ "PRICE_CHOOSE" | translate }}
        </div>
      </a>
    </div>
    <div class="contact-link">
      <a [routerLink]="'/contact'">
        {{ "PRICE_ASK_MORE_LINK" | translate }}
      </a>
    </div>
  </div>
</div>
