<div class="footer">
  <div class="top">
    <div class="logo">
      <img src="assets/icons/logo-light.svg" alt="" class="logo-light" />
    </div>
    <div class="contact-details">
      <div class="phone">
        <div class="phone-title">
          {{ "FOOTER_CONTACT_PHONE_TITLE" | translate }}
        </div>
        <div class="phone-details">
          <div>{{ "FOOTER_CONTACT_PHONE_DETAILS_1" | translate }}</div>
          <div>{{ "FOOTER_CONTACT_PHONE_DETAILS_2" | translate }}</div>
        </div>
      </div>
      <div class="address">
        <div class="address-title">
          {{ "FOOTER_CONTACT_ADDRESS_TITLE" | translate }}
        </div>
        <div class="address-details">
          <div>{{ "FOOTER_CONTACT_ADDRESS_DETAILS_1" | translate }}</div>
          <div>{{ "FOOTER_CONTACT_ADDRESS_DETAILS_2" | translate }}</div>
          <div>{{ "FOOTER_CONTACT_ADDRESS_DETAILS_3" | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom">
    <div class="copyright">Copyright © {{ getCurrentYear() }} Breello</div>
  </div>
</div>
