<div class="close-button" (click)="dismissModal()">
  <div class="text">{{ "CONTACT_MODAL_CLOSE" | translate }}</div>
  <div class="icon">
    <svg-icon-sprite
      [src]="'assets/sprites/sprite.svg#close'"
      [width]="'20px'"
      [height]="'20px'"
    ></svg-icon-sprite>
  </div>
</div>
<div *ngIf="valueProposition" class="value-proposition-modal">
  <div class="icon-container">
    <div class="icon-black"></div>
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#' + valueProposition.svg"
        [width]="'24px'"
        [height]="'24px'"
      ></svg-icon-sprite>
    </div>
  </div>
  <div class="title">{{ valueProposition.title | translate }}</div>
  <div class="main-text" [innerHTML]="safeHtml"></div>
</div>
