import { Component, Input } from '@angular/core';
import { Options } from '@popperjs/core/lib/';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

export interface IExtraServiceCard {
  svg: string;
  text: string;
  infoText: string;
  event: string;
}

@Component({
  selector: 'app-extra-service-card',
  templateUrl: './extra-service-card.component.html',
  styleUrl: './extra-service-card.component.scss',
})
export class ExtraServiceCardComponent {
  @Input() public card?: IExtraServiceCard;

  constructor(
    private $gaService: GoogleAnalyticsService
  ) {}

  /**
   * Setting position of tooltip
   * @param {Partial<Options>} options The existing popper options
   * @returns {Partial<Options>} The returned popper options
   */
  public popperOptions(options: Partial<Options>): Partial<Options> {
    options.modifiers?.push({
      name: 'offset',
      enabled: true,
      phase: 'main',
      options: {
        offset: [12, 10],
      },
    });

    return options;
  }

  /**
   * Tracks contact clicks.
   */
  public trackContactClicks(): void {
    this.$gaService.event('Extra-Services-Contact-CTA', 'Click', `${this.card?.event}-CTA`);
  }
}
