import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PlatformStatus } from 'src/app/enumerations/platform-status.enumeration';
import { IExtraServiceCard } from '../extra-service-card/extra-service-card.component';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValuePropositionModalComponent } from '../value-proposition-modal/value-proposition-modal.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

export interface IProperty {
  name: string;
  price: string;
  picture: string;
  number: string;
  hasBoost: boolean;
  hasVIP: boolean;
  category: string;
  link?: string;
}

export interface IPricePanel {
  type: string;
  value?: string;
  limited?: boolean;
  valuePrice?: string;
  description: string;
  boosted?: boolean;
  platforms?: {
    link: string;
    status: PlatformStatus;
  }[];
  features?: {
    vip: boolean;
    text: string;
  }[];
}

export interface ISteps {
  number: string;
  title: string;
  description: string;
}

export interface IValueProposition {
  title: string;
  description: string;
  fulltext: string;
  svg: string;
  event: string;
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [
    trigger('textAnimation', [
      state('void', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('void => visible', [
        animate('0.5s')
      ]),
      transition('visible => void', [
        animate('0.5s')
      ])
    ])
  ]
})
export class LandingComponent implements OnInit, OnDestroy {
  // Public properties.
  public pricePanels: IPricePanel[] = [];
  public properties: IProperty[] = [];
  public valuePropositions: IValueProposition[] = [];
  public scrolling = true;
  public extraServices: IExtraServiceCard[] = [];
  public steps: ISteps[] = [];
  public bannerTimeInterval = 2000;
  public bannerTimer?: ReturnType<typeof setInterval>;
  public bannerAnimatedText: string = 'BANNER_TEXT_ANIMATED_1';
  public pricesHasBeenViewed = false;
  public textState: string = 'visible';

  @ViewChild('pricesPanel') public pricesPanel!: ElementRef<HTMLElement>;

  /**
   *
   */
  @HostListener('window:scroll', ['$event'])
  public onWindowScroll() {
    if (this.pricesPanel) {
      const elementPosition = this.pricesPanel.nativeElement.offsetTop;
      const scrollPosition = window.scrollY + window.innerHeight / 2 - 100;

      // set `true` when scrolling has reached current element
      const reached = elementPosition <= scrollPosition;

      // set `true` when scrolling has passed current element height
      // const passed = window.scrollY >= elementBottomPosition;

      if (reached && !this.pricesHasBeenViewed) {
        this.pricesHasBeenViewed = true;
        this.$gaService.event(
          'Landing-Page-Price-Panels-View',
          'Scroll',
          'Price-Panels-View',
        );
      }
    }
  }

  constructor(
    private router: Router,
    private titleService: Title,
    private $gaService: GoogleAnalyticsService,
    private modalService: NgbModal,
  ) { }

  /**
   *
   */
  ngOnInit(): void {
    this.titleService.setTitle('Breello');

    this.pricePanels = [
      {
        type: 'PRICE_MAIN',
        value: 'PRICE_FREE',
        limited: true,
        valuePrice: '7€',
        description: 'PRICE_MAIN_DESCRIPTION',
        features: [
          {
            vip: false,
            text: 'PRICE_FEATURE_1',
          },
          {
            vip: false,
            text: 'PRICE_FEATURE_2',
          },
          {
            vip: false,
            text: 'PRICE_FEATURE_3',
          },
          {
            vip: false,
            text: 'PRICE_FEATURE_4',
          },
          {
            vip: false,
            text: 'PRICE_FEATURE_5',
          },
          {
            vip: false,
            text: 'PRICE_FEATURE_6',
          },
        ],
      },
      {
        type: 'PRICE_BOOST',
        valuePrice: '15€',
        description: 'PRICE_BOOST_DESCRIPTION',
        boosted: true,
        features: [
          {
            vip: true,
            text: 'PRICE_FEATURE_7',
          },
          {
            vip: false,
            text: 'PRICE_FEATURE_1',
          },
          {
            vip: false,
            text: 'PRICE_FEATURE_2',
          },
          {
            vip: false,
            text: 'PRICE_FEATURE_3',
          },
          {
            vip: false,
            text: 'PRICE_FEATURE_4',
          },
          {
            vip: false,
            text: 'PRICE_FEATURE_5',
          },
        ],
      },
    ];

    this.properties = [
      {
        name: 'Διαμέρισμα, 2 δωμάτια, Σίβηρι, Κασσάνδρας',
        price: '€138.000',
        picture: 'property_2575.jpg',
        number: '2575',
        hasBoost: false,
        hasVIP: false,
        category: 'SALE',
        link: 'https://www.homeview.gr/akinita/akinito-2575'
      },
      {
        name: 'Μεζονέτα, Χαριλάου, Ανατολική Θεσσαλονίκη',
        price: 'Κατόπιν επικοινωνίας',
        picture: 'property_2750.jpg',
        number: '2750',
        hasBoost: false,
        hasVIP: false,
        category: 'SALE',
        link: 'https://www.homeview.gr/akinita/akinito-2750'
      },
      {
        name: 'Μεζονέτα, 2 δωμάτια, Πευκοχώρι, Κασσάνδρας',
        price: '€95.000',
        picture: 'property_1366.jpg',
        number: '1366',
        hasBoost: false,
        hasVIP: false,
        category: 'SALE',
        link: 'https://www.homeview.gr/akinita/akinito-1366'
      },
      {
        name: 'Μεζονέτα, 4 δωμάτια, Βούλα, Νότια Προάστια',
        price: '€1.100.000',
        picture: 'property_2659.png',
        number: '2659',
        hasBoost: false,
        hasVIP: false,
        category: 'SALE',
        link: 'https://www.homeview.gr/akinita/akinito-2659'
      },
    ];

    this.valuePropositions = [
      {
        title: 'VALUE_PROPOSITION_1_TITLE',
        description: 'VALUE_PROPOSITION_1_TEXT',
        fulltext: 'VALUE_PROPOSITION_1_TEXT_FULL',
        svg: 'real-estate-action-house-percent',
        event: 'NO-REALTOR-PAY'
      },
      {
        title: 'VALUE_PROPOSITION_2_TITLE',
        description: 'VALUE_PROPOSITION_2_TEXT',
        fulltext: 'VALUE_PROPOSITION_2_TEXT_FULL',
        svg: 'structuredwiring',
        event: 'ADVERTISE'
      },
      {
        title: 'VALUE_PROPOSITION_3_TITLE',
        description: 'VALUE_PROPOSITION_3_TEXT',
        fulltext: 'VALUE_PROPOSITION_3_TEXT_FULL',
        svg: 'support',
        event: 'GUIDANCE'
      },
      {
        title: 'VALUE_PROPOSITION_4_TITLE',
        description: 'VALUE_PROPOSITION_4_TEXT',
        fulltext: 'VALUE_PROPOSITION_4_TEXT_FULL',
        svg: 'withinbuildingzone',
        event: 'NO-PROCEDURALS'
      },
      {
        title: 'VALUE_PROPOSITION_5_TITLE',
        description: 'VALUE_PROPOSITION_5_TEXT',
        fulltext: 'VALUE_PROPOSITION_5_TEXT_FULL',
        svg: 'house-exhibition',
        event: 'FULL-PARTNER-NETWORK'
      },
      {
        title: 'VALUE_PROPOSITION_6_TITLE',
        description: 'VALUE_PROPOSITION_6_TEXT',
        fulltext: 'VALUE_PROPOSITION_6_TEXT_FULL',
        svg: 'performance',
        event: 'EFFICIENT-LISTINGS'
      },
    ];

    this.steps = [
      {
        number: '1',
        title: 'LANDING_PAGE_STEPS_TITLE_1',
        description: 'LANDING_PAGE_STEPS_DESCR_1',
      },
      {
        number: '2',
        title: 'LANDING_PAGE_STEPS_TITLE_2',
        description: 'LANDING_PAGE_STEPS_DESCR_2',
      },
      {
        number: '3',
        title: 'LANDING_PAGE_STEPS_TITLE_3',
        description: 'LANDING_PAGE_STEPS_DESCR_3',
      },
      {
        number: '4',
        title: 'LANDING_PAGE_STEPS_TITLE_4',
        description: 'LANDING_PAGE_STEPS_DESCR_4',
      },
      {
        number: '5',
        title: 'LANDING_PAGE_STEPS_TITLE_5',
        description: 'LANDING_PAGE_STEPS_DESCR_5',
      },
    ];

    this.extraServices = [
      {
        text: 'EXTRA_SERVICE_1_TITLE',
        infoText: 'EXTRA_SERVICE_1_TEXT',
        svg: 'house-exhibition',
        event: 'HOUSE-EXHIBITION'
      },
      {
        text: 'EXTRA_SERVICE_2_TITLE',
        infoText: 'EXTRA_SERVICE_2_TEXT',
        svg: 'energy_class',
        event: 'ENERGY-CLASS'
      },
      {
        text: 'EXTRA_SERVICE_3_TITLE',
        infoText: 'EXTRA_SERVICE_3_TEXT',
        svg: 'photoshooting',
        event: 'PHOTOSHOOTING'
      },
      {
        text: 'EXTRA_SERVICE_4_TITLE',
        infoText: 'EXTRA_SERVICE_4_TEXT',
        svg: 'list_bullets',
        event: 'DIGITAL_ID'
      },
      {
        text: 'EXTRA_SERVICE_5_TITLE',
        infoText: 'EXTRA_SERVICE_5_TEXT',
        svg: 'price',
        event: 'PRICE'
      },
      {
        text: 'EXTRA_SERVICE_6_TITLE',
        infoText: 'EXTRA_SERVICE_6_TEXT',
        svg: 'pivoting_2',
        event: 'ARBITRARY'
      },
    ];

    this.bannerTimer = setInterval(() => {
      this.textState = 'void';
      setTimeout(() => {
        if (this.bannerAnimatedText === 'BANNER_TEXT_ANIMATED_1') {
          this.bannerAnimatedText = 'BANNER_TEXT_ANIMATED_2';
        } else {
          this.bannerAnimatedText = 'BANNER_TEXT_ANIMATED_1';
        }
        this.textState = 'visible';
      }, 500);
    }, 2500);
  }

  /**
   * Prevents scrolling when sliding menu is open.
   */
  public updateScrolling(): void {
    this.scrolling = !this.scrolling;
    if (!this.scrolling) {
      $('body').css('overflow', 'hidden');
    } else {
      $('body').css('overflow', 'auto');
    }
  }

  /**
   * Scrolls to Prices Panel
   */
  public scrollToPrices(): void {
    const headerOffset = 240;
    const elementPosition =
      this.pricesPanel.nativeElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  /**
   * Opens the ValuePropositionModal
   * @param {IValueProposition} valueProposition The value proposition
   */
  public openValuePropositionModal(valueProposition: IValueProposition) {
    this.$gaService.event(
      'Landing-Page-Value-Proposition-View',
      'Click',
      `${valueProposition.event}-View`,
    );

    const modalRef = this.modalService.open(ValuePropositionModalComponent, {
      centered: true,
      size: 'xl',
      backdrop: true,
      modalDialogClass: 'value-proposition-modal-dialog',
    });

    modalRef.componentInstance.valueProposition = valueProposition;
  }

  /**
   * Tracks contact clicks.
   */
  public trackContactClicks(): void {
    this.$gaService.event(
      'Landing-Page-Contact',
      'Click',
      'Contact',
    );
  }

  /**
   *
   */
  ngOnDestroy(): void {
    clearInterval(this.bannerTimer);
  }
}
