import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sliding-menu',
  templateUrl: './sliding-menu.component.html',
  styleUrls: ['./sliding-menu.component.scss'],
})
export class SlidingMenuComponent {
  // Inputs/Ouputs.
  @Output() public menuClosed = new EventEmitter<void>();

  // Public properties.
  public backOfficeURL: string;

  constructor(
    private router: Router,
    private $gaService: GoogleAnalyticsService
  ) {
    this.backOfficeURL = environment.backOfficeURL;
  }

  /**
   * Navigates user to contact page.
   */
  public navigateToContact(): void {
    this.menuClosed.emit();
    this.router.navigate(['/contact']);
    window.scroll(0, 0); // reset the scroll position to the top left of the document.
  }

  /**
   * Tracks publish clicks.
   */
  public trackPublishClicks(): void {
    this.$gaService.event('Mobile-Menu-Publish-CTA', 'Click', 'Publish-CTA');
  }

  /**
   * Navigates user to home page.
   */
  public navigateToHome(): void {
    this.router.navigate(['/']);
    this.menuClosed.emit();
    window.scroll(0, 0); // reset the scroll position to the top left of the document.
  }

  /***
   * Emits event that closes the menu.
   */
  /**
   *
   */
  public closeMenu(): void {
    this.menuClosed.emit();
  }
}
