<app-navigation-bar
  [isLight]="true"
  (menuToggled)="updateScrolling()"
></app-navigation-bar>

<div class="terms-and-conditions">
  <div class="title">
    {{ "TERMS_TITLE" | translate }}
  </div>

  <div class="terms" [innerHTML]="'TERMS_PART_1' | translate"></div>
  <div class="terms">
    {{ "TERMS_PART_2" | translate }}
  </div>
  <div class="terms">
    {{ "TERMS_PART_3" | translate }}
  </div>
  <div class="terms">
    {{ "TERMS_PART_4" | translate }}
  </div>
  <div class="terms">
    {{ "TERMS_PART_5" | translate }}
  </div>
  <div class="terms">
    {{ "TERMS_PART_6" | translate }}
  </div>
  <div class="terms">
    {{ "TERMS_PART_7" | translate }}
  </div>
  <div class="terms" [innerHTML]="'TERMS_PART_8' | translate"></div>
  <div class="terms">
    {{ "TERMS_PART_9" | translate }}
  </div>

  <div class="two-col-content">
    <div class="first-col">
      <div class="image">
        <img src="assets/icons/breello-contact.svg" alt="" />
      </div>
    </div>
    <div class="second-col">
      <div class="title">
        <div class="text">{{ "LANDING_PAGE_KNOW_MORE" | translate }}</div>
      </div>
      <div
        class="main-text"
        [innerHTML]="'LANDING_PAGE_FAQ_TEXT' | translate"
      ></div>
      <div class="button" (click)="navigateToContact()">
        <div class="text">{{ "LANDING_PAGE_CONTACT_BUTTON" | translate }}</div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
