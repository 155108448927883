import { Input, Component } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  // Inputs/Outputs
  @Input() public title: string = '';
  @Input() public number: string = '';
  @Input() public price: string = '';
  @Input() public image: string = '';
  @Input() public link?: string = '';
  @Input() public hasVIP = false;
  @Input() public hasBoost = false;
  @Input() public category: string = '';

  constructor(
    private $gaService:  GoogleAnalyticsService
  ) {}

  /**
   * Opens the item in a new tab.
   */
  public openItem(): void {
    this.$gaService.event(
      'Property-Carousel-View',
      'Click',
      `HomeView-${this.number}-View`,
    );

    if (this.link != null && this.link.trim() !== '') {
      window.open(this.link, '_blank');
    }
  }
}
